













import { Component, Mixins, Prop } from 'vue-property-decorator'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import { StudentGoal } from '@/models/api/studentGoal'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalOthersGoals extends Mixins(ModalBaseMethod) {
  @Prop()
  otherGoals!: StudentGoal[]

  private subjectGoalValue(subjectGoal: { subjectName: string; subjectCode: string; score: number; unit: string }) {
    return subjectGoal.subjectName + ':' + subjectGoal.score + subjectGoal.unit
  }
}
