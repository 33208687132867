











































































import { Component, Ref, Prop, Mixins, Vue } from 'vue-property-decorator'
import SelectBase from '@/components/atoms/SelectBase.vue'
import DatePickerIcon from '@/components/atoms/DatePickerIcon.vue'
import ModalOthersGoals from '@/components/organisms/ModalOthersGoals.vue'
import RadioGroup from '@/components/molecules/RadioGroup.vue'
import { StudentGoal, Targetable } from '@/models/api/studentGoal'
import LocalMoment from '../atoms/LocalMoment.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

export type GoalFormData = {
  name: string
  comment: string
  from: string // 通常モードの場合は値が入る
  to: string // 通常モードの場合は値が入る
  targetableId: number // 定期テスト、講習会モードの場合は値が入る
  subjectUnitType: number // 教科毎の目標の単位タイプ
  subjectScores: SubjectScores // 教科毎の目標
  termSubjectScores: SubjectScores // 定期テスト点数目標
}

type SubjectScores = {
  su?: number | null
  ei?: number | null
  ko?: number | null
  ri?: number | null
  sh?: number | null
}

@Component({
  components: {
    SelectBase,
    DatePickerIcon,
    ModalOthersGoals,
    RadioGroup,
  },
})
export default class GoalSettingForm extends Mixins(LocalMoment, ClassModeMethods) {
  @Ref() modalOthersGoals!: ModalOthersGoals

  private subjects = { su: '算数/数学', ei: '英語', ko: '国語', ri: '理科', sh: '社会' }

  @Prop()
  formData!: GoalFormData

  @Prop()
  otherGoals!: StudentGoal[]

  @Prop({ default: null })
  targetable?: Targetable | null

  // 期間選択が選択式であるか
  private get isSelectTargetAble() {
    return this.isTestMode || this.isSeminarMode
  }

  private get targetSettingName() {
    // 定期テストモード
    if (this.isTestMode) return 'テストの設定'
    if (this.isSeminarMode) return '講習会の設定'
    return ''
  }

  private get innerFormData(): GoalFormData {
    return this.formData
  }

  private set innerFormData(newVal) {
    this.$emit('edit-form', newVal)
  }

  private readonly SUBJECT_UNITS = [
    { type: 0, label: '問題数', name: '問' },
    { type: 1, label: '正解数', name: '問' },
    { type: 2, label: '学習時間', name: '時間' },
    { type: 3, label: '理解度', name: '％' },
  ]

  private get unitsRadioItems(): { label: string; value: number }[] {
    const items: { label: string; value: number }[] = []
    this.SUBJECT_UNITS.forEach((i) => {
      items.push({ label: i.label, value: i.type })
    })
    return items
  }

  private get unitName() {
    return this.SUBJECT_UNITS[this.formData.subjectUnitType].name
  }

  private onSelectTo(newVal: string) {
    if (this.isNormalMode) {
      // 通常モードの場合はTOを変更した際は自動的にタイトルに反映する
      this.innerFormData.name = `${this.today().format('YYYY/MM/DD')}〜${newVal}`
    }

    this.innerFormData.to = newVal
  }

  private onSelectUnit(newVal: number) {
    this.innerFormData.subjectUnitType = newVal
  }

  private onInputSubjectScore(key: string, value: string) {
    if (this.innerFormData.subjectUnitType == 3 && parseInt(value) > 100) value = '100'
    else if (value.length > 3) value = value.substr(0, 3)

    this.innerFormData.subjectScores[key] = null
    this.innerFormData.subjectScores[key] = parseInt(value) || null
  }

  private onInpurTermSubjectScore(key: string, value: string) {
    if (parseInt(value) > 100) value = '100'

    this.innerFormData.termSubjectScores[key] = null
    this.innerFormData.termSubjectScores[key] = parseInt(value) || null
  }

  private showOthersGoals() {
    this.modalOthersGoals.show()
  }
}
